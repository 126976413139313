import React, { Component } from "react"
import { Col } from "reactstrap"
import { injectIntl } from "gatsby-plugin-intl"
import { connect } from "react-redux";
import axios from "../../api/axios"

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      achievementsCategories: []
    }

    axios(this.props.intl.locale, "achievement_categories/")
      .then(response => {
        const achievementsCategories = [...response.data]
        this.setState({ achievementsCategories: achievementsCategories });
      })
  }

  render() {
    const activeCircleStyle = { borderColor: "#389cf6", transform: "scale(1.1)", backgroundColor: "#85bff261" }
    const activeCategoryTextStyle = { color: "#389cf6", fontSize: "medium" }
    return (
      <>
        {this.state.achievementsCategories.map((category, i) => (
          <Col key={i} lg={1} md={2} sm={2} xs={4} className="cat-col"
            onClick={() => this.props.handleAchievementCategoryClick(category)}>
            <div key={i} className="circle" >
              <div className="img-box" style={this.props.selectedCategoryIds &&
              this.props.selectedCategoryIds.includes(category.id) ? activeCircleStyle
              : {}}>
              <img src={category.icon} alt="img" />
              </div>
            
              <h6 style={this.props.selectedCategoryIds &&
                this.props.selectedCategoryIds.includes(category.id) ? activeCategoryTextStyle : {}}>
                {category.name}
              </h6>
            </div>
          </Col>
        ))}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    regions: state.regions.regions,
    selectedRegion: state.regions.selectedRegion,
  }
}

export default connect(mapStateToProps)(injectIntl(Categories))
