import React, { Component } from "react"
import { Col, Container, Row, Spinner } from "reactstrap"
import { injectIntl, FormattedMessage, navigate } from "gatsby-plugin-intl"

import Layout from "../containers/Layout"
import SEO from "../components/seo"
import axios from "../api/axios"
import AchievmentsCategories from "../components/achievements/categories"
import GallerySection from "../components/gallerySection"
import Axios from "axios"

class AchievementsDetailsPage extends Component {

    state = {
        achievement: { title_ar: "" },
        images: [],
        imagesThumb: [],
        isLoading: true
    }
    componentDidMount() {
        const id = new URL(this.props.location.href).searchParams.get("id");
        const requestAchievements = axios(this.props.intl.locale, `achievements/?id=${id}`)
        const requestImages = axios(this.props.intl.locale, `achievements_images/?achievement=${id}`)

        Axios.all([requestAchievements, requestImages])
            .then(Axios.spread((...responses) => {
                const achievements = responses[0]
                const images = responses[1]
                this.setState({
                    achievement: achievements.data.results[0],
                    isLoading: false
                })
                const urlImages = []
                const urlThumbImages = []
                images.data && images.data.map(image => {
                    urlImages.push(image.image)
                    urlThumbImages.push(image.thumbnail_url)
                })
                this.setState({ images: urlImages, imagesThumb: urlThumbImages })
            }))
    }

    handleAchievementCategoryClick = (achievementCategory) => {
        navigate("/achievements/?category=" + achievementCategory.id)
    }

    render() {
        const isArabicLocale = this.props.intl.locale === "ar";

        return (
            <Layout>
                <SEO title="Achievments" />
                <div className="main">
                    <div className="section text-center">
                        <div className="mt-5 p-4 flex" style={{ backgroundColor: "#d3d3d36e" }} >
                            <Container >
                                <Row>
                                    <h3 className="mt-1"><FormattedMessage id="achievements_app" /></h3>
                                </Row>
                            </Container>
                        </div>
                        <br />
                        <Container>
                            <Row className="justify-content-lg-between">
                                <AchievmentsCategories handleAchievementCategoryClick={this.handleAchievementCategoryClick} />
                            </Row>

                            <br />
                            <Row>
                                <Col>

                                    {this.state.isLoading ? <Spinner /> : null}
                                    <h3 align={isArabicLocale ? "right" : "left"}> {isArabicLocale ? this.state.achievement.title_ar : this.state.achievement.title_en}</h3>

                                    <hr align={isArabicLocale ? "right" : "left"}
                                        style={{
                                            height: "2px",
                                            width: "20vw",
                                            background: "#2ac3ef",
                                            marginBottom: "30px",
                                        }} />
                                    {this.state.achievement.image ?
                                        <Row className=" justify-content-center">
                                            <div style={{ height: "35em" }}>
                                            <img className="img-fluid"
                                                src={this.state.achievement.image} alt="img" />
                                            </div>
                                        </Row> : null}
                                    <br />
                                    <br />
                                    <Row>
                                        {this.state.achievement.show_short_description_in_details ?
                                            <p align={isArabicLocale ? "right" : "left"}>
                                                {isArabicLocale ? this.state.achievement.short_description_ar : this.state.achievement.short_description_en}
                                            </p> : null}
                                    </Row>
                                    <br />
                                    <Row>
                                        <p dangerouslySetInnerHTML={{ __html: isArabicLocale ? this.state.achievement.description_ar : this.state.achievement.description_en }} align={isArabicLocale ? "right" : "left"} ></p>
                                    </Row>
                                    <Row>
                                        <p dangerouslySetInnerHTML={{ __html: this.state.achievement.external_links ? this.state.achievement.external_links : '' }} align={isArabicLocale ? "right" : "left"} ></p>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{ direction: "ltr" }}>
                                <Col sm="12" md={{ size: 6, offset: 3 }}>
                                    <GallerySection titleMsgId="agreement_gallery" images={this.state.images} imagesThumb={this.state.imagesThumb} />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </Layout>
        )
    }
}


export default injectIntl(AchievementsDetailsPage)
